

export const Rangolisvg = () => {
    return (
        <div className="rangoli-wrp">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.36 137.96">

                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <path className="cls-6 hospitality" data-count="11" d="m134.54,57.55c-16.99,17.1-41.69,20.45-62.52,9.51,9.84-15.53,42.22-20.54,62.52-9.51Z" />
                        <path className="cls-6 hospitality" data-count="12" d="m120.87,111.87c-24.09-.93-43.27-16.84-49.32-39.58,18.09-3.27,43.7,17.17,49.32,39.58Z" />
                        <path className="cls-6 hospitality" data-count="13" d="m67.95,137.12c-14.67-19.14-14.66-44.06-1.02-63.23,14.07,11.84,14.68,44.6,1.02,63.23Z" />
                        <path className="cls-6 hospitality" data-count="14" d="m14.99,112.27c5.36-23.51,24.54-39.42,48.01-41.16-.13,18.39-24.94,39.78-48.01,41.16Z" />
                        <path className="cls-6 hospitality" data-count="8" d="m.82,54.38c21.75-10.4,46.14-5.24,62.08,12.07-14.49,11.32-46.67,5.15-62.08-12.07Z" />
                        <path className="cls-6 hospitality" data-count="10" d="m69.23,64.31c-1.99-16.93.93-32.05,12.46-44.63,3.41-3.72,7.53-6.85,11.58-9.9,2.61-1.97,3.79-1.21,4.15,1.99,2.37,21.08-8.76,42.18-28.18,52.55Z" />
                        <path className="cls-6 hospitality" data-count="9" d="m65.84,64.65c-14.54-8.89-24.63-20.53-27.41-37.36-.82-4.98-.74-10.15-.63-15.22.07-3.27,1.39-3.73,4.12-2.04,18.04,11.16,27.77,32.94,23.92,54.62Z" />
                    </g>
                    <circle className="cls-3 experience" data-count="10" cx="66.2" cy="22.44" r="6.25" />
                    <circle className="cls-6 hospitality" data-count="17" cx="65.3" cy="7.26" r="4.02" />
                    <circle className="cls-6 hospitality" data-count="18" cx="109.41" cy="34.29" r="4.02" />
                    <circle className="cls-6 hospitality" data-count="19" cx="122.89" cy="82.51" r="4.02" />
                    <circle className="cls-6 hospitality" data-count="15" cx="9.37" cy="81.12" r="4.02" />
                    <circle className="cls-6 hospitality" data-count="16" cx="20.72" cy="35.02" r="4.02" />
                    <circle className="cls-4 aesthetics" data-count="1" cx="67.85" cy="69.09" r="2.48" />
                    <circle className="cls-3 experience" data-count="11" cx="100" cy="43.72" r="6.25" />
                    <circle className="cls-3 experience" data-count="12" cx="109.86" cy="80.09" r="6.25" />
                    <circle className="cls-3 experience" data-count="13" cx="86.51" cy="107.94" r="6.25" />
                    <circle className="cls-3 experience" data-count="14" cx="48.93" cy="107.2" r="6.25" />
                    <circle className="cls-3 experience" data-count="8" cx="24.09" cy="77.55" r="6.25" />
                    <circle className="cls-3 experience" data-count="9" cx="32.28" cy="40.76" r="6.25" />
                    <circle className="cls-1 aesthetics" data-count="12" cx="27.95" cy="31.48" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="14" cx="69.39" cy="13.31" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="13" cx="61.05" cy="13.75" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="11" cx="22.77" cy="42.19" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="15" cx="100.99" cy="33.24" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="5" cx="91.16" cy="49.54" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="4" cx="67.11" cy="32.8" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="23" cx="20.8" cy="35.06" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="3" cx="41.32" cy="46.92" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="16" cx="110.11" cy="43.39" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="17" cx="118.37" cy="73.72" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="18" cx="116.61" cy="88.46" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="19" cx="97.25" cy="112.88" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="6" cx="99.01" cy="76.8" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="20" cx="84.27" cy="118.16" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="7" cx="81.19" cy="96.6" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="21" cx="50.39" cy="117.94" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="22" cx="37.8" cy="111.82" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="8" cx="54.79" cy="95.94" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="10" cx="11.67" cy="73.06" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="2" cx="34.33" cy="75.26" r="1.54" />
                    <circle className="cls-1 aesthetics" data-count="9" cx="14.97" cy="86.04" r="1.54" />
                    <circle className="cls-6 hospitality" data-count="20" cx="92.85" cy="118.77" r="4.02" />
                    <circle className="cls-2 logistics" data-count="18" cx="100" cy="43.73" r="3.11" />
                    <circle className="cls-2 logistics" data-count="17" cx="66.2" cy="22.44" r="3.11" />
                    <circle className="cls-2 logistics" data-count="16" cx="32.28" cy="40.76" r="3.11" />
                    <circle className="cls-2 logistics" data-count="15" cx="24.09" cy="77.55" r="3.11" />
                    <circle className="cls-2 logistics" data-count="21" cx="48.93" cy="107.2" r="3.11" />
                    <circle className="cls-2 logistics" data-count="20" cx="86.51" cy="107.94" r="3.11" />
                    <circle className="cls-2 logistics" data-count="19" cx="109.86" cy="80.09" r="3.11" />
                    <g>
                        <path className="cls-2 logistics" data-count="9" d="m65.91,63.93c-11.75-6.18-19.99-15.04-22.5-28.86-.74-4.09-.77-8.41-.78-12.66,0-2.73,1.04-3.23,3.25-2.05,14.57,7.78,22.71,25.14,20.03,43.58Z" />
                        <g>
                            <path className="cls-3 experience" data-count="3" d="m64.67,62.6c-9.58-5.76-16.29-13.4-18.28-24.59-.59-3.31-.59-6.75-.58-10.14,0-2.18.86-2.5,2.67-1.4,11.89,7.23,18.47,21.64,16.2,36.13Z" />
                            <path className="cls-5 hospitality" data-count="2" d="m65.1,63.35c-6.58-3.96-11.19-9.21-12.56-16.89-.41-2.27-.41-4.64-.4-6.96,0-1.5.59-1.72,1.83-.96,8.17,4.97,12.69,14.87,11.13,24.82Z" />
                            <path className="cls-2 logistics" data-count="2" d="m64.78,62.11c-4.35-2.35-7.47-5.59-8.57-10.49-.33-1.45-.4-2.97-.46-4.47-.04-.96.33-1.12,1.15-.68,5.4,2.95,8.6,9.18,7.88,15.64Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="10" d="m69.72,64.31c-2.29-13.31-.29-25.37,9.21-35.74,2.81-3.07,6.25-5.69,9.63-8.25,2.18-1.65,3.22-1.1,3.64,1.42,2.75,16.57-6.11,33.66-22.48,42.57Z" />
                        <g>
                            <path className="cls-3 experience" data-count="4" d="m70.02,62.49c-1.29-11.29.69-21.36,8.39-29.72,2.28-2.47,5.03-4.55,7.74-6.57,1.74-1.3,2.53-.8,2.76,1.33,1.53,14.06-5.92,28.09-18.89,34.96Z" />
                            <path className="cls-5 hospitality" data-count="3" d="m69.7,63.3c-.89-7.75.47-14.67,5.77-20.42,1.57-1.7,3.46-3.12,5.32-4.52,1.2-.9,1.74-.55,1.9.91,1.05,9.66-4.07,19.3-12.98,24.02Z" />
                            <path className="cls-2 logistics" data-count="3" d="m70.49,62.29c-.8-4.97-.13-9.46,3.11-13.31.96-1.14,2.13-2.11,3.29-3.06.74-.61,1.1-.4,1.25.53.96,6.18-2.05,12.54-7.64,15.85Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="8" d="m62.63,66.3c-12.16,5.87-24.3,7.27-36.89,1-3.72-1.85-7.19-4.44-10.59-6.99-2.19-1.64-1.94-2.79.36-3.89,15.17-7.22,34.04-3.41,47.12,9.88Z" />
                        <g>
                            <path className="cls-7 experience" data-count="1" d="m60.8,66.51c-10.49,4.35-20.72,5.23-30.88.12-3.01-1.51-5.76-3.58-8.45-5.63-1.73-1.32-1.46-2.21.52-3.02,13.09-5.35,28.64-2.05,38.82,8.52Z" />
                            <path className="cls-5 hospitality" data-count="1" d="m61.66,66.61c-7.21,2.99-14.23,3.59-21.21.08-2.06-1.04-3.96-2.46-5.81-3.87-1.19-.9-1.01-1.52.36-2.07,8.99-3.67,19.67-1.41,26.66,5.86Z" />
                            <path className="cls-2 logistics" data-count="1" d="m60.48,66.12c-4.55,2.14-9.06,2.73-13.65.68-1.36-.61-2.62-1.47-3.85-2.32-.79-.55-.69-.95.17-1.35,5.68-2.63,12.62-1.49,17.34,2.98Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="11" d="m73.11,66.5c9.91-9.17,21.12-14.05,34.99-11.74,4.1.68,8.18,2.14,12.17,3.59,2.57.93,2.67,2.1.79,3.82-12.4,11.34-31.56,13.21-47.95,4.33Z" />
                        <g>
                            <path className="cls-7 experience" data-count="2" d="m74.8,65.77c8.76-7.23,18.29-11.06,29.5-9.15,3.32.56,6.55,1.74,9.73,2.91,2.04.75,2.05,1.68.39,3.04-10.95,8.94-26.79,10.33-39.61,3.2Z" />
                            <path className="cls-5 hospitality" data-count="4" d="m73.95,65.93c6.02-4.97,12.56-7.6,20.26-6.28,2.28.39,4.5,1.2,6.68,2,1.4.52,1.41,1.16.27,2.09-7.52,6.14-18.4,7.1-27.21,2.2Z" />
                            <path className="cls-2 logistics" data-count="4" d="m75.22,66.04c3.73-3.38,7.86-5.26,12.86-4.65,1.48.18,2.93.64,4.36,1.09.92.29.94.7.23,1.34-4.66,4.18-11.64,5.11-17.45,2.22Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="14" d="m63.1,71.71c-3.55,13.03-10.46,23.11-23.45,28.5-3.84,1.59-8.07,2.52-12.22,3.41-2.67.57-3.38-.37-2.7-2.82,4.51-16.19,19.76-27.93,38.37-29.08Z" />
                        <g>
                            <path className="cls-3 experience" data-count="7" d="m62.06,73.23c-3.6,10.78-9.65,19.07-20.17,23.39-3.11,1.28-6.48,2-9.79,2.69-2.13.45-2.63-.34-1.94-2.37,4.55-13.39,17.24-22.96,31.9-23.7Z" />
                            <path className="cls-5 hospitality" data-count="7" d="m62.7,72.64c-2.47,7.4-6.63,13.1-13.85,16.07-2.14.88-4.45,1.37-6.73,1.85-1.46.31-1.81-.24-1.33-1.63,3.13-9.2,11.85-15.77,21.91-16.28Z" />
                            <path className="cls-2 logistics" data-count="7" d="m61.56,73.22c-1.37,4.84-3.88,8.63-8.44,10.75-1.35.63-2.83,1.01-4.28,1.39-.93.24-1.17-.1-.91-1.01,1.74-6.01,7.16-10.5,13.62-11.13Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="12" d="m72.01,72.32c13.49.53,24.87,4.99,33.04,16.44,2.42,3.38,4.27,7.29,6.07,11.14,1.16,2.48.4,3.38-2.14,3.26-16.79-.75-31.66-12.97-36.97-30.84Z" />
                        <g>
                            <path className="cls-3 experience" data-count="5" d="m73.73,72.99c11.31,1.08,20.75,5.11,27.32,14.39,1.94,2.74,3.4,5.87,4.82,8.94.91,1.98.26,2.64-1.88,2.42-14.07-1.42-26.25-11.64-30.27-25.75Z" />
                            <path className="cls-5 hospitality" data-count="5" d="m73.01,72.5c7.77.74,14.25,3.51,18.77,9.89,1.34,1.88,2.34,4.03,3.31,6.14.63,1.36.18,1.81-1.29,1.66-9.66-.98-18.03-7.99-20.79-17.69Z" />
                            <path className="cls-2 logistics" data-count="5" d="m73.83,73.49c5.02.25,9.28,1.84,12.38,5.81.92,1.17,1.62,2.52,2.31,3.85.44.86.17,1.16-.78,1.11-6.25-.34-11.84-4.61-13.91-10.77Z" />
                        </g>
                    </g>
                    <g>
                        <path className="cls-2 logistics" data-count="13" d="m67.06,73.31c7.89,10.96,11.38,22.67,7.39,36.16-1.18,3.99-3.12,7.85-5.04,11.64-1.24,2.44-2.41,2.4-3.89.32-9.74-13.69-9.27-32.93,1.54-48.12Z" />
                        <g>
                            <path className="cls-3 experience" data-count="6" d="m67.59,75.08c6.11,9.58,8.75,19.5,5.48,30.39-.96,3.22-2.53,6.29-4.07,9.3-1,1.94-1.92,1.83-3.06.01-7.54-11.96-6.99-27.85,1.65-39.71Z" />
                            <path className="cls-5 hospitality" data-count="6" d="m67.53,74.21c4.2,6.58,6.01,13.39,3.77,20.88-.66,2.21-1.74,4.32-2.8,6.39-.68,1.33-1.32,1.25-2.11,0-5.18-8.22-4.8-19.13,1.14-27.28Z" />
                            <path className="cls-2 logistics" data-count="6" d="m67.26,75.46c2.9,4.11,4.26,8.44,3.04,13.33-.36,1.44-.99,2.83-1.61,4.2-.4.88-.81.85-1.35.07-3.58-5.14-3.66-12.17-.08-17.59Z" />
                        </g>
                    </g>
                    <circle className="cls-2 logistics" data-count="24" cx="126.92" cy="116.18" r="2.88" />
                    <circle className="cls-2 logistics" data-count="23" cx="99.45" cy="3.38" r="2.88" />
                    <circle className="cls-2 logistics" data-count="22" cx="36.26" cy="3.38" r="2.88" />
                    <circle className="cls-2 logistics" data-count="25" cx="9.37" cy="116.62" r="2.88" />
                </g>
            </svg>
        </div>
    );
};

export default Rangolisvg;
