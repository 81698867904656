import { type ReactNode } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import './App.scss'
import History from './pages/History'
import Home from './pages/Home'
import Layout from './pages/Layout'
import TestData from './pages/TestData'
import Rangoli from './pages/Rangoli/Rangoli'

function App(): ReactNode {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/history' element={<History />} />
          <Route path='/test-data' element={<TestData />} />       
          <Route path='/rangoli' element={<Rangoli />} />   
          <Route path="*" element={<p>🤫 There&apos;s no page here.</p>} />
        </Route>
     
      </Routes>
     
    </BrowserRouter>
  )
}

export default App
