import React, { useEffect, useState } from "react";
import GarlandAnimation from "./Star/GarlandAnimation";
import Confetti from "./Star/Confetti";
import Rangolisvg from "./Rangolisvg";


export const Rangoli = () => {
    const [logistics, setLogistics] = useState(0)
    const [aesthetics, setAesthetics] = useState(0)
    const [experience, setExperience] = useState(0)
    const [hospitality, setHospitality] = useState(0)

    const [garlandClass, setGarlandClass] = useState('')
    const [showConfetti, setShowConfetti] = useState(false)
  const divRef = React.createRef();
//  const divRect = divRef.current.getBoundingClientRect();

    function rangoliFill(count, className) {
        const elements = document.querySelectorAll("." + className);
        elements.forEach((element) => {
            const dataCount = parseInt(element.getAttribute("data-count"));
            if (dataCount <= count) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }


    const garlandAnimationType = (logistics, hospitality, experience, aesthetics) => {
        if (experience == "14") {
            setGarlandClass("experience-garland")
        }
        else if (hospitality == "20") {
            setGarlandClass("hospitality-garland")
        }
        else if (aesthetics == "23") {
            setGarlandClass("aesthetics-garland")
        }
        else if (logistics == "25") {
            setGarlandClass("logistics-garland")
        }

    }
    const showConfettiFun = (logistics, hospitality, experience, aesthetics) => {    
        if (hospitality == "20" && experience == "14" && logistics == "25" && aesthetics == "23") {
            setShowConfetti(true)
            console.log("true")
        }

    }

    useEffect(() => {
        rangoliFill(hospitality, "hospitality");
        rangoliFill(experience, "experience");
        rangoliFill(logistics, 'logistics')
        rangoliFill(aesthetics, 'aesthetics')
        garlandAnimationType(logistics, hospitality, experience, aesthetics)
        showConfettiFun(logistics, hospitality, experience, aesthetics)
    }, [logistics, hospitality, experience, aesthetics]);




    return (
        <>

            <div className="todo-animation-card">
                {/* {garlandClass && <GarlandAnimation key={garlandClass} className={garlandClass} />} */}
               
                <div className="to-do-animation-head">
                    <h1>Great job Ranveer, you’re almost there!</h1>
                    <p>Lets Create your Rangoli of Romance</p>
                </div>
                <div className="todo-animation" ref={divRef}>
                    <figure className="emoji-left">
                        <img src="/images/rangoli-left.svg"></img>
                    </figure>
                    <Rangolisvg />
                    <figure className="emoji-right">
                        <img src="/images/rangoli-right.svg"></img>
                    </figure>
                    
                </div>
                {/* {showConfetti && <Confetti />} */}
                <ul className="todo-category">
                    <li className="logistic-count">
                        <span></span>
                        <p>
                            Logistics<br></br>{logistics}
                        </p>
                    </li>
                    <li className="aesthetics-count">
                        <span></span>
                        <p>
                            Aesthetics<br></br>{aesthetics}
                        </p>
                    </li>
                    <li className="hospitality-count">
                        <span></span>
                        <p>
                            Hospitality<br></br>{hospitality}
                        </p>
                    </li>
                    <li className="experience-count">
                        <span></span>
                        <p>
                            Experience<br></br>{experience}
                        </p>
                    </li>
                </ul>

            </div>
            <div className="todo-animation-card text-box-wrap">
                <ul className="todo-category">
                    <li className="logistic-count">
                        <label>Logistics</label>
                        <input
                            type="number"
                            min="0"
                            max="25"
                            onChange={(e) => setLogistics(Math.min(e.target.value, 25))}
                            value={logistics}

                        ></input>
                    </li>
                    <li className="aesthetics-count">
                        <label>Aesthetics</label>
                        <input
                            type="number"
                            min="0"
                            max="23"
                            onChange={(e) => setAesthetics(Math.min(e.target.value, 23))}
                            value={aesthetics}
                        ></input>
                    </li>
                    <li className="hospitality-count">
                        <label>Hospitality</label>
                        <input
                            type="number"
                            min="0"
                            max="20"
                            onChange={(e) => setHospitality(Math.min(e.target.value, 20))}
                            value={hospitality}
                        ></input>
                    </li>
                    <li className="experience-count">
                        <label>Experience</label>
                        <input
                            type="number"
                            min="0"
                            max="14"
                            onChange={(e) => setExperience(Math.min(e.target.value, 14))}
                            value={experience}
                        ></input>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Rangoli;
