import { Select } from 'antd';
import React, { useState, type ReactNode } from 'react';
import { CATEGORY_OPTIONS, DEFAULT_CATEGORY, setDefaultCategory } from '../utils/constants';

const vendorCategorySelectStyle: React.CSSProperties = {
  marginBottom: '20px'
};

type PropTypes = {
  defaultCategory?: string,
  onChange: (category: string) => void
}

function CategorySelector({ onChange, defaultCategory = DEFAULT_CATEGORY }: PropTypes): ReactNode {
  const [category, setCategory] = useState<string>(defaultCategory);

  return <Select
    style={vendorCategorySelectStyle}
    placeholder="Select a vendor category"
    optionFilterProp="children"
    onChange={(selected: any) => {
      setCategory(selected)
      setDefaultCategory(selected)
      onChange?.(selected)
    }}
    value={category}
    options={CATEGORY_OPTIONS}
  />
}

export default CategorySelector