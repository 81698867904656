import { Typography } from 'antd';
import { useState, type ReactNode } from 'react';
import PreferenceForm from '../components/PreferenceForm';
import VendorDataTable from '../components/VendorDataTable';
import { getRecommendation } from '../utils/service';
const { Title } = Typography;

function Home(): ReactNode {
    const [state, updateState] = useState({
        recommendations: [],
        error: null,
        type: '',
        loading: false,
        selectedVendorIds: [],
        historyId: null
    });
    const {
        recommendations,
        error,
        type,
        loading,
        selectedVendorIds,
        historyId
    } = state

    function setState(data: any) {
        updateState({ ...state, ...data })
    }

    function handleRowSelection(row: any) {
        const newVendorIds: string[] = [...selectedVendorIds]
        const { vendorId } = row;
        newVendorIds.push(vendorId)
        setState({ selectedVendorIds: newVendorIds })
    }

    async function handlePreferenceSubmit(formData: any) {
        if (loading) return
        setState({ loading: true, type: formData.type, recommendations: [] })
        const data = await getRecommendation(formData)
        setState({ ...data, loading: false })
    }

    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <Title level={4}>B&G Preferences</Title>
            </div>

            <PreferenceForm
                onSubmit={handlePreferenceSubmit}
                error={error}
                loading={loading}
                selectedVendorIds={selectedVendorIds}
                historyId={historyId} />

            <VendorDataTable
                type={type}
                recommendations={recommendations}
                title='Recommendations'
                handleRowSelection={handleRowSelection}
                loading={loading} />
        </>
    )
}

export default Home