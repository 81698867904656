import { Select, SelectProps } from "antd";
import React from "react";
import { CATEGORY_OPTIONS } from "../utils/constants";

/**
 * rc for reusing list of vendor cateogries as select
 */
export default function VendorCategorySelect(props: SelectProps): React.ReactNode {
    return (
        <Select
            placeholder="Select a vendor cateogry"
            optionFilterProp="children"
            options={CATEGORY_OPTIONS}
            {...props}
        />
    );
}