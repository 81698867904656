import { type ReactNode } from 'react';
import TestDataUploader from '../components/TestDataUploader';

function TestData(): ReactNode {

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <TestDataUploader />
      </div>
    </>
  )
}

export default TestData