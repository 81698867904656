import { ApiEndpoints } from './constants'
import { callApi } from './helper'

export async function getRecommendation(data: any) {
  try {
    if (data.glamTypes) {
      data.glamTypes = data.glamTypes.map((glamType: number) => {
        return {
          glamType,
          userType: 'bride'
        }
      })
    }

    const resData = await callApi(ApiEndpoints.getRecommendation, data)
    const { type, recommendations, historyId } = resData.data
    return { type, recommendations, error: null, historyId }
  } catch (error: any) {
    return { type: '', recommendations: [], error: error.message }
  }
}

export async function getHistoryItems(data: any) {
  try {
    const resData = await callApi(ApiEndpoints.getHistoryItems, data)
    return { historyList: resData.data, error: null }
  } catch (error: any) {
    return { historyList: [], error: error.message }
  }
}

function parseJson(data: any, defaultValue: any) {
  return data ? JSON.parse(data) : defaultValue
}

export async function getHistoryData(data: any) {
  try {
    const resData = await callApi(ApiEndpoints.getHistoryData, data)

    const result = parseJson(resData.data?.[0].result, [])
    const payload = parseJson(resData.data?.[0].payload, '')
    if ("type" in payload) {
      delete payload.type
    }

    return { result, payload, error: null }
  } catch (error: any) {
    return { historyList: [], error: error.message }
  }
}