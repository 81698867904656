import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Alert, Button, Card, Col, Input, Row, Space, notification } from 'antd';
import React, { useState, type ReactNode, useEffect } from 'react';

import { mappings } from '../jsonforms';
import { saveFeedback } from '../services/feedback';
import { DEFAULT_CATEGORY, setDefaultCategory } from '../utils/constants';
import VendorCategorySelect from './VendorCategorySelect';

const { TextArea } = Input

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const submitButtonStyle: React.CSSProperties = {
    margin: '20px auto'
};

export interface PreferenceFormProps {
    onSubmit: (data: any) => void;
    error: string | null,
    loading: boolean,
    selectedVendorIds: string[],
    historyId: string | null
}

export default function PreferenceForm({ error, loading, onSubmit, selectedVendorIds, historyId }: PreferenceFormProps): ReactNode {
    const [data, setData] = useState({});
    const [hasError, setError] = useState<boolean>(false);
    const [category, setCategory] = useState<string>(DEFAULT_CATEGORY);
    const [feedback, setFeedback] = useState('')
    const [feedbackError, setFeedbackError] = useState('')
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDefaultCategory(category)
    },[category])

    const [savingFeedback, setSavingFeedback] = useState<boolean>(false);

    const selectedMapping = mappings[category];

    function openNotificationWithIcon(type: NotificationType, message: string, description: string) {
        api[type]({ message, description });
    };

    async function handleFeedbackSubmit() {
        setFeedbackError('')

        if (!feedback) {
            setFeedbackError('Please enter your feedback')
            return
        }

        setSavingFeedback(true)
        const { error, success } = await saveFeedback({ selectedVendorIds, feedback, historyId, type: category })
        setSavingFeedback(false)
        if (success) {
            openNotificationWithIcon('success', 'Success', 'Feedback saved successfully')
        } else {
            openNotificationWithIcon('error', 'Error', error)
        }
    }

    return (
        <Row>
            {contextHolder}
            <Col span={16}>
                <Card>
                    <VendorCategorySelect
                        style={{ minWidth: '350px' }}
                        size='large'
                        onChange={setCategory}
                        value={category}
                        id='control-start'
                    />

                    <JsonForms
                        schema={selectedMapping?.schema}
                        uischema={selectedMapping?.uischema}
                        renderers={materialRenderers}
                        cells={materialCells}
                        data={data}
                        onChange={({ data, errors }) => {
                            setData(data);
                            setError(!!errors?.length);
                        }}
                    />

                    <Button
                        style={submitButtonStyle}
                        disabled={hasError || loading}
                        type='primary'
                        size='large'
                        loading={loading}
                        onClick={() => {
                            onSubmit({ ...data, type: category })
                        }}>
                        Get Recommendations
                    </Button>
                    {error ? <Alert message={error} type="error" showIcon /> : null}
                </Card>
            </Col>
            {historyId ? <Col span={8}>
                <Card>
                    <Space direction='vertical'>
                        {selectedVendorIds.length
                            ? <Alert message={JSON.stringify(selectedVendorIds).replaceAll('"', ' ')} type="error" />
                            : null}
                        <TextArea
                            cols={50}
                            autoSize={{ minRows: 10, maxRows: 10 }}
                            placeholder='Enter your feedback'
                            onChange={e => setFeedback(e.target.value)}
                        />
                        <Button
                            type="primary"
                            disabled={savingFeedback}
                            onClick={handleFeedbackSubmit}
                        >
                            Submit
                        </Button>
                        {feedbackError ? <Alert message={feedbackError} type="error" showIcon /> : null}
                    </Space>
                </Card>
            </Col> : null}

        </Row>
    )
}
