export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const ENV = process.env.REACT_APP_ENV

export const ApiEndpoints = {
  getRecommendation: `${API_BASE_URL}getRecommendations`,
  uploadTestData: `${API_BASE_URL}uploadTestData`,
  getHistoryItems: `${API_BASE_URL}getHistoryItems`,
  getHistoryData: `${API_BASE_URL}getHistoryData`,
  saveFeedback: `${API_BASE_URL}saveFeedback`,
}

export const CATEGORY_TYPES = {
  'baraat': 'baraat',
  'photography': 'photography',
  'cinematography': 'cinematography',
  'catering': 'catering',
  'floralDecor': 'floralDecor',
  'beauty': 'beauty',
  'djEntertainment': 'djEntertainment',
}

export const CATEGORY_OPTIONS = [
  {
    value: CATEGORY_TYPES.baraat,
    label: 'Baraat',
  },
  {
    value: CATEGORY_TYPES.photography,
    label: 'Photography',
  },
  {
    value: CATEGORY_TYPES.cinematography,
    label: 'Cinematography',
  },
  {
    value: CATEGORY_TYPES.catering,
    label: 'Catering',
  },
  {
    value: CATEGORY_TYPES.floralDecor,
    label: 'Floral & Decor',
  },
  {
    value: CATEGORY_TYPES.beauty,
    label: 'Beauty',
  },
  {
    value: CATEGORY_TYPES.djEntertainment,
    label: 'DJ & Entertainment',
  },
]

export let DEFAULT_CATEGORY = CATEGORY_TYPES.floralDecor

export function setDefaultCategory(category: string) {
  DEFAULT_CATEGORY = category
}