import { Alert, Checkbox, Row, Select, Space, Typography } from 'antd';
import { useEffect, useState, type ReactNode } from 'react';
import CategorySelector from '../components/CategorySelector';
import Loading from '../components/Loading';
import VendorDataTable from '../components/VendorDataTable';
import { DEFAULT_CATEGORY } from '../utils/constants';
import { formatTimestamp } from '../utils/helper';
import { getHistoryData, getHistoryItems } from '../utils/service';
const { Title } = Typography;

type HistoryRow = {
  id: string,
  timestamp: Date,
  feedback: string | null,
  wrongVendorIds: string | null,
}

function History(): ReactNode {
  const [state, updateState] = useState({
    error: null,
    historyList: [],
    selectedHistoryId: null,
    type: DEFAULT_CATEGORY,
    loading: false,
    onlyFeedback: false
  });
  const [cachedHistoryData, setCachedHistoryData] = useState<{ [key: string]: any }>({})
  const {
    error,
    historyList,
    selectedHistoryId,
    type,
    loading,
    onlyFeedback
  } = state

  const historyRow: any = historyList?.find((r: any) => r.id === selectedHistoryId) ?? {}
  let { feedback, wrongVendorIds = '' } = historyRow
  wrongVendorIds = wrongVendorIds?.split(',')

  let historyData = []
  let payload = ''
  if (selectedHistoryId && cachedHistoryData[selectedHistoryId]) {
    historyData = cachedHistoryData[selectedHistoryId].historyData
    payload = cachedHistoryData[selectedHistoryId].payload
  }

  async function getList() {
    setState({ loading: true })
    const data = await getHistoryItems({ type })
    setState({ ...data, loading: false })
  }

  async function fetchHistoryData() {
    if (!selectedHistoryId) return

    setState({ loading: true })
    const { payload, result, error } = await getHistoryData({ type, id: selectedHistoryId })
    setCachedHistoryData({ ...cachedHistoryData, [selectedHistoryId]: { payload, historyData: result } })
    setState(({ error, loading: false }))
  }

  useEffect(() => {
    getList()
  }, [])

  useEffect(() => {
    if (!selectedHistoryId) return

    if (cachedHistoryData?.[selectedHistoryId]) {
      setState(cachedHistoryData[selectedHistoryId])
    } else {
      fetchHistoryData()
    }
  }, [selectedHistoryId])

  function setState(data: any) {
    updateState({ ...state, ...data })
  }

  function handleOnlyFeedbackChange(e: any) {
    setState({ onlyFeedback: e.target.checked })
  }

  return (
    <>
      <Row>
        <Space align='center' direction='horizontal'>
          <Title level={4}>History</Title>
          {error ? <Alert message={error} type="error" showIcon /> : null}

          <CategorySelector defaultCategory={DEFAULT_CATEGORY} onChange={selected => {
            setState({ ...state, type: selected })
          }} />

          <Select
            disabled={loading}
            onChange={id => setState({ selectedHistoryId: id })}
            optionFilterProp="children"
            placeholder="Select a history entry"
            style={{ marginLeft: '20px' }}
            value={selectedHistoryId}
            options={historyList?.filter((r: any) => {
              return !onlyFeedback || r?.feedback
            }).map((i: HistoryRow) => {
              return {
                value: i.id,
                label: formatTimestamp(i.timestamp)
              }
            })}
          />

          <Checkbox onChange={handleOnlyFeedbackChange}>Only feedback</Checkbox>
          {loading ? <Loading /> : null}
        </Space>
      </Row>

      <Row>
        <Space direction='vertical'>
          {payload ? <Alert message={JSON.stringify(payload).replaceAll('"', ' ')} type="info" showIcon /> : null}
          {feedback ? <Alert message={feedback} type="error" showIcon /> : null}
        </Space>
      </Row>

      <VendorDataTable
        type={type}
        recommendations={historyData}
        title=''
        handleRowSelection={() => { }}
        wrongVendorIds={wrongVendorIds}
        isHistory={true}
      />
    </>
  )
}

export default History