import React, { useState, type ReactNode, useEffect } from 'react';
import { Button, Card, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { customRequest } from '../services/testDataUploader';
import { DEFAULT_CATEGORY, setDefaultCategory } from '../utils/constants';
import TextArea from 'antd/es/input/TextArea';
import VendorCategorySelect from './VendorCategorySelect';

export default function TestDataUploader(): ReactNode {
    const [category, setCategory] = useState<string>(DEFAULT_CATEGORY);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        setDefaultCategory(category)
    },[category])

    useEffect(() => {
        if (typeof error === 'undefined') {
            return;
        }

        Modal.error({
            title: error?.message || "Failed to upload test data!",
            onCancel() {
                setError(undefined);
            },
            content: (
                <>
                    {error?.cause && <TextArea rows={10} value={error?.cause as string}></TextArea>}
                </>
            )
        })
    }, [error]);

    return (
        <Card title="Upload test data">
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
            }}>
                <VendorCategorySelect
                    style={{ minWidth: '350px' }}
                    size='large'
                    onChange={setCategory}
                    value={category}
                />

                <Upload
                    disabled={!category}
                    multiple={false}
                    maxCount={1}
                    customRequest={customRequest(category, setError)}
                    accept='text/csv'>
                    <Button
                        style={{ margin: "auto", marginTop: '20px' }}
                        size='large'
                        icon={<UploadOutlined />}>Upload Data</Button>
                </Upload>
            </div>
        </Card>
    );
}