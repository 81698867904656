import { ApiEndpoints } from '../utils/constants';

export async function saveFeedback({ selectedVendorIds, feedback, historyId, type}: any) {
  try {
    const wrongVendorIds = selectedVendorIds?.filter(Boolean).join(",") ?? ''
    const response = await fetch(ApiEndpoints.saveFeedback, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wrongVendorIds, feedback, historyId, type}),
    })

    const resData = await response.json()
    if(!response.ok) {
      return {error: resData?.message, success: false }
    }
    return {error: '', success: true }
  } catch (error: any) {
    console.log('❌ ', error)
    return {error: error?.message, success: false }
  }
}