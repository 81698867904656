import { Checkbox, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { CATEGORY_TYPES } from '../utils/constants';
const { Title } = Typography;

type VendorDataTableType = {
    type: string,
    recommendations: any[],
    title: string,
    handleRowSelection: any,
    wrongVendorIds?: string | null,
    isHistory?: boolean,
    loading?: boolean
}

function RenderWrongEntry({ row, handleRowSelection, isHistory, wrongVendorIds }: any) {
    if (isHistory) {
        return wrongVendorIds?.includes(row.vendorId) ? 'Y' : ''
    }

    return <Checkbox onChange={() => handleRowSelection(row)}>
    </Checkbox>
}

function wrongEntryColumn({ RenderWrongEntry, handleRowSelection, isHistory, wrongVendorIds }: any) {
    return {
        title: "Wrong Entry",
        dataIndex: "",
        render: (_: any, row: any) => <RenderWrongEntry
            row={row}
            handleRowSelection={handleRowSelection}
            isHistory={isHistory}
            wrongVendorIds={wrongVendorIds}
        />
    }
}

function renderOptions(text: any, record: any, columnToName: any) {
    return columnToName.map((col: any) => {
        return +record[col.name] === 1 ? col.text : ""
    }).filter(Boolean).join(', ')
}

const vendorIdCol = {
    title: "Vendor Id",
    dataIndex: "vendorId",
}

const withinBudgetCol = {
    title: "Within Budget",
    dataIndex: "withinBudget",
    width: 75
}

const bestMatchCol = {
    title: "Best Match",
    dataIndex: "bestMatch",
}

const noOfContractsCol = {
    title: "Contracts Count",
    dataIndex: "noOfContractClosed",
}

const joiningDateCol = {
    title: "Joining Date",
    dataIndex: "joiningDate",
}

const milesCol = {
    title: "Miles",
    dataIndex: "miles",
}

const actualMilesCol = {
    title: "Actual Miles",
    dataIndex: "actualMiles",
    render(value: any, row: any) {
        return Math.floor(value)
    }
}

const cityCol = {
    title: "City",
    dataIndex: "cityId",
}

const budgetRangeCol = {
    title: "Budget",
    dataIndex: "",
    render(text: any, record: any) {
        return record.budget ?? `${record.budgetLowerLimit}-${record.budgetUpperLimit}`
    }
}

const budgetUpperLimit = {
    title: "Budget",
    dataIndex: "budgetUpperLimit",
}

const localVendorCol = {
    title: "Local Vendor",
    dataIndex: "localVendor",
    width: 75,
}

const suportsStyleCol = {
    title: "Supports Style",
    dataIndex: "suportsStyle",
    width: 75,
}

const supportsCuisineType = {
    title: "Supports Cuisine",
    dataIndex: "suportsCuisine",
    width: 75,
}

const supportsGlamType = {
    title: "Supports Glam Type",
    dataIndex: "suportsGlamType",
    width: 75,
}

const supportsType = {
    title: "Supports Type",
    dataIndex: "supportsType",
    width: 75,
}

const supportsGenre = {
    title: "Supports Genre",
    dataIndex: "supportsGenre",
    width: 75,
}

const supportsVibe = {
    title: "Supports Vibe",
    dataIndex: "supportsVibe",
    width: 75,
}

const supportsPersonalityType = {
    title: "Supports Personality Type",
    dataIndex: "suportsPersonalityType",
    width: 75,
}

const personalityTypeCol = {
    title: "Personality Type",
    dataIndex: "",
    render: (text: any, record: any) => renderOptions(text, record, [
        { name: "personality_chai", text: "Chai" },
        { name: "personality_champagne", text: "Champagne" },
        { name: "personality_water", text: "Water" },
        { name: "personality_whiskey", text: "Whiskey" },
        { name: "personality_wine", text: "Wine" },
    ]),
}



function initialColumns({ handleRowSelection, isHistory, wrongVendorIds }: any) {
    return [
        wrongEntryColumn({ RenderWrongEntry, handleRowSelection, isHistory, wrongVendorIds }),
        vendorIdCol,
    ]
}

const commonColumns: any = [
    withinBudgetCol,
    bestMatchCol,
    noOfContractsCol,
    joiningDateCol,
    milesCol,
    actualMilesCol,
    cityCol,
]

const ColumnConfigurations = ({ handleRowSelection, isHistory, wrongVendorIds }: any) => ({
    [CATEGORY_TYPES.baraat]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        {
            title: "Supports Mode Of Tran",
            dataIndex: "supportModeOfTran",
        },
        ...commonColumns,
        budgetRangeCol,
        {
            title: "Transportation Types",
            dataIndex: "",
            render: (text: any, record: any) => renderOptions(text, record, [
                { name: "transTypeHorse", text: "Horse" },
                { name: "transTypeMotorcycle", text: "Motorcycle" },
                { name: "transTypeSegway", text: "Segway" },
                { name: "transTypeElephant", text: "Elephant" },
                { name: "transTypeRickshaw", text: "Rickshaw" },
                { name: "transTypeOnFoot", text: "On foot" },
                { name: "transTypeConvertible", text: "Convertible" },
                { name: "transTypeTrolley", text: "Trolley" },
            ]),
        },
    ],
    [CATEGORY_TYPES.photography]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        suportsStyleCol,
        supportsPersonalityType,
        localVendorCol,
        ...commonColumns,
        budgetUpperLimit,
        {
            title: "Styles",
            dataIndex: "",
            render: (text: any, record: any) => renderOptions(text, record, [
                { name: "photography_candid", text: "Candid" },
                { name: "photography_captures_emotion", text: "Captures emotion" },
                { name: "photography_classic", text: "Classic" },
                { name: "photography_dramatic", text: "Dramatic" },
                { name: "photography_romantic", text: "Romantic" },
                { name: "photography_storytelling", text: "Storytelling" },
            ]),
        },
        personalityTypeCol
    ],
    [CATEGORY_TYPES.cinematography]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        suportsStyleCol,
        supportsPersonalityType,
        localVendorCol,
        ...commonColumns,
        budgetUpperLimit,
        {
            title: "Styles",
            dataIndex: "",
            render: (text: any, record: any) => renderOptions(text, record, [
                { name: "style_bollywood", text: "Bollywood" },
                { name: "style_romantic", text: "Romantic" },
            ]),
        },
        personalityTypeCol
    ],
    [CATEGORY_TYPES.catering]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        supportsCuisineType,
        supportsPersonalityType,
        localVendorCol,
        ...commonColumns,
        budgetUpperLimit,
        {
            title: "Cuisine Types",
            dataIndex: "",
            width: 200,
            render: (text: any, record: any) => renderOptions(text, record, [
                { "name": "cuisineTypeImnotsure", "text": "I'm not sure" },
                { "name": "cuisineTypeChaat", "text": "Chaat" },
                { "name": "cuisineTypeGujrati", "text": "Gujrati" },
                { "name": "cuisineTypeIndoChinese", "text": "Indo-Chinese" },
                { "name": "cuisineTypeItalianFusion", "text": "Italian Fusion" },
                { "name": "cuisineTypeMexicanFusion", "text": "Mexican Fusion" },
                { "name": "cuisineTypeNorthIndian", "text": "North Indian" },
                { "name": "cuisineTypePunjabi", "text": "Punjabi" },
                { "name": "cuisineTypeSouthIndian", "text": "South Indian" }
            ]),
        },
        personalityTypeCol
    ],
    [CATEGORY_TYPES.beauty]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        ...commonColumns,
        budgetUpperLimit,
    ],
    [CATEGORY_TYPES.floralDecor]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        supportsGlamType,
        supportsPersonalityType,
        localVendorCol,
        ...commonColumns,
        budgetUpperLimit,
        {
            title: "Glam Types",
            dataIndex: "",
            width: 120,
            render: (text: any, record: any) => renderOptions(text, record, [
                { "name": "glamTypeModern", "text": "Modern" },
                { "name": "glamTypeGlam", "text": "Glam" },
                { "name": "glamTypeRomantic", "text": "Romantic" },
                { "name": "glamTypeEclectic", "text": "Eclectic" }

            ]),
        },
        personalityTypeCol
    ],
    [CATEGORY_TYPES.djEntertainment]: [
        ...initialColumns({ handleRowSelection, isHistory, wrongVendorIds }),
        supportsType,
        supportsGenre,
        supportsVibe,
        supportsPersonalityType,
        localVendorCol,
        ...commonColumns,
        budgetUpperLimit,
        {
            title: "Type",
            dataIndex: "",
            width: 120,
            render: (text: any, record: any) => renderOptions(text, record, [
                { "name": "type_combination", "text": "A mix of mediums" },
                { "name": "type_dj", "text": "DJ (digital playlist/mixes)" },
                { "name": "type_live", "text": "Live band" },
            ]),
        },
        {
            title: "Genre",
            dataIndex: "",
            width: 120,
            render: (text: any, record: any) => renderOptions(text, record, [
                { "name": "genre_hipHop", text: "Hip Hop", },
                { "name": "genre_rB", text: "R&B", },
                { "name": "genre_top40", text: "Top 40", },
                { "name": "genre_90S", text: "90s", },
                { "name": "genre_bollywood", text: "Bollywood", },
                { "name": "genre_punjabi", text: "Punjabi", },
                { "name": "genre_southIndian", text: "South Indian", },
                { "name": "genre_gujrati", text: "Gujrati", },
                { "name": "genre_edm", text: "EDM", }
            ]),
        },
        {
            title: "Vibe",
            dataIndex: "",
            width: 120,
            render: (text: any, record: any) => renderOptions(text, record, [
                { "name" : "vibe_familyFriendly", text:  "A fun family-friendly party" },
                { "name" : "vibe_withFriends", text:  "A good time partying with friends" },
                { "name" : "vibe_club", text:  "Club style; the party of the century" },
            ]),
        },
        personalityTypeCol
    ]
})

const VendorDataTable: React.FC<VendorDataTableType> = ({
    type,
    recommendations,
    title,
    handleRowSelection,
    isHistory,
    wrongVendorIds,
    loading = false
}) => {
    if (!type) return null

    const typeColumnConfig = ColumnConfigurations({ handleRowSelection, isHistory, wrongVendorIds })[type]
    if (!typeColumnConfig) {
        throw new Error(`No column configuration found for type: ${type}`)
    }

    let columns = typeColumnConfig.map((col: any) => {
        col.align = "center" as const
        return col
    })

    useEffect(() => {
        if (recommendations?.length) {
            const element = document.getElementById('control-start')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [recommendations])

    return (
        <div style={{ marginTop: '30px' }}>
            {title ? <Title level={4}>{title}</Title> : null}
            <Table
                columns={columns}
                dataSource={recommendations}
                rowKey="vendorId"
                size='small'
                pagination={false}
                rowClassName={(record, index) => {
                    if (record.bestMatch) return 'best-match'
                    return index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }}
                loading={loading}
            />
        </div>
    );
}

export default VendorDataTable