import { JsonFormsInitStateProps } from "@jsonforms/react";
import baraat from "./baraat/index";
import photography from "./photography/index";
import cinematography from "./cinematography/index";
import catering from "./catering/index";
import floralDecor from "./floralDecor/index";
import beauty from "./beauty/index";
import djEntertainment from "./djEntertainment/index";

export const mappings: { [key: string]: Partial<JsonFormsInitStateProps> } = {
    baraat,
    photography,
    cinematography,
    catering,
    floralDecor,
    beauty,
    djEntertainment
}