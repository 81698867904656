import papa from "papaparse";
import { ApiEndpoints } from "../utils/constants";

export function customRequest(type: string, cbError: (error: any) => void) {
    return async (opts: any) => {
        try {
            const data = await jsonFromCsvFile(opts.file);
            await uploadTestData({ type, data });
            opts?.onSuccess("Data uploaded!");
        } catch (error) {
            opts?.onError({ message: "Failed to upload data" });
            cbError(error);
        }
    };
}

export async function uploadTestData(data: { data: Array<any>, type: string }) {
    const response = await fetch(ApiEndpoints.uploadTestData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })

    const responseData = await response.json()

    if (response.status > 399) {
        throw new Error("Failed to upload data", { cause: JSON.stringify(responseData) });
    }

    return responseData;
}

export function jsonFromCsvFile(file: File): Promise<Array<any>> {
    const stream = file as Blob;
    return new Promise((resolve, reject) => {
        papa.parse(stream as any, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
                resolve(results.data);
            },
            error(error) {
                reject(error);
            }
        });
    });
}