import { Col, Layout as L, Row, Typography } from 'antd';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const { Header, Content } = L;
const { Title } = Typography;

function Layout(): React.ReactNode {
    return (
        <L className="layout">
            <Header style={{ marginBottom: '10px' }}>
                <Row >
                    <Col span={3}>
                        <span>
                            <Link to="/">
                                <Title type='success' style={{ fontSize: '30px' }} >VMA</Title>
                            </Link>
                        </span>
                    </Col>
                    <Col span={2}>
                        <span><Link to="/history">History</Link></span>
                    </Col>
                    <Col span={2}>
                        <span><Link to="/test-data">Test Data</Link></span>
                    </Col>
                    <Col span={2}>
                        <span><Link to="/rangoli">Rangoli</Link></span>
                    </Col>
                </Row>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div>
                    <Outlet />
                </div>
            </Content>
        </L>
    );
};


export default Layout;
